import { GtmCode } from '@hubcms/domain-tracking';

export { fr as dateFnsLocale } from 'date-fns/locale/fr';
export const locale = 'fr-LU';
export const timeZone = 'Europe/Luxembourg';
export const archiveTitle = 'Archives';
export const gtmCodes: GtmCode[] = [{ code: 'GTM-MJWKBXZ', isParent: true }, { code: 'GTM-PTFPDLB' }];
export const brandCode = 'vl';
export const adunitBrandCode = 'virgule';
export const b2bUrlBrandCode = 'virgule';
export const b2bDMPMetaTagBrandCode = 'stp';
export const footerLogo = 'logo-main-inverse';
export const headerDateFormat = 'EEEE, d MMMM yyyy';
export const isSubscriptionMenuEnabled = false;
export const isTopicsMenuEnabled = false;
export const authorPrefix = 'auteurs/';
export const searchPrefix = 'recherche/';
export const tagsPrefix = 'tag/';
export const tagsb2bPrefix = 'sponsorise/';
export const weatherSectionPage = '/meteo/';
export const cimIdentifier = '.FhKu.wyh76pcTbRKeiVbWYmDrGNTOcSRtGvOSr3E7b.L7';
export const cimLanguage = 'FR';
export const tickarooClientId = '64772af30bfac400a065fb8b';
export const heroBlockIntroProps = {
  size: 'lg',
  weight: 'strong',
};
export const domain = 'virgule.lu';
export const enablePersonalizedArticleList = true;
export const isAppTopicsMenuEnabled = false;
